/* Off-canvas styles */
.mobile-navbar .translate-x-full {
  transform: translateX(100%);
}

.mobile-navbar .translate-x-0 {
  transform: translateX(0);
}
@media screen and (min-width: 768px) {
    .mobile-navbar {
       align-items: center;
    }
}